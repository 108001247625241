@import url(https://fonts.googleapis.com/css?family=Bungee);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: none;
          animation: none;
  
  pointer-events: none;
  align-content: center;
  align-self: center;
  float: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 150;
  color: #5a2fc2;
}

.App-link {
  color: #122227;
}

.rounded {
  border-radius: 150px;
  background-color: #5a2fc2 !important;
  color: white;
  margin: 5px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.homefont {
  font-family: "Circular", "Open Sans", Arial, Helvetica, sans-serif;
  margin-left: 10px;
  margin-top: 50px;
  margin-right: 10px;
  font-size: 72 !important;
  size: 100;  
  font-weight: 900;
  line-height: 1.1;
}

.titlefont {
  font-family: 'Bungee';
  color: #5a2fc2;
  text-align: center;
  float: center !important;
  align-self: center !important;
  justify-content: center !important;
  font-size: 72 !important;
  
}
.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
  align-content: center;
  justify-content: center;
  
}

.navbar .navbar-collapse {
  text-align: center;
}
.subfont {
  font-family: "Circular", "Open Sans", Arial, Helvetica, sans-serif;    
  font-size: 24 !important;  
  line-height: 1.5;
  font-weight: 400;
  margin: 2%;
}
.requeststyle {
  background-color: #5a2fc2;
  color: #5a2fc2;
}
.imagestyle {
  align-items: center;
  margin-bottom: 50px;
  margin-top: 15%;
}
.formstyle {
  align-content: center;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .imagestyle {
    margin: 50px;
  }
}
.navbar-size-new {
  font-size: 72 !important;
}
h1 {font-size: 48px!important;}

@media (max-width: 991px) {
  h1 {font-size: 36px!important;}
}

.secondary_screen {
  background-color: #5a2fc2;
  width: 100%;
}
